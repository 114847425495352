/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavState } from '../types';

export const initialState: NavState = {
	isScrollAtTop: true,
	navWidth: 0,
};

export const nvaSlice = createSlice({
  name: 'nav',
  // 初始值
  initialState,
  reducers: {
    navChanged: (state: NavState, { payload }: PayloadAction<NavState>) => {
			state.isScrollAtTop = payload.isScrollAtTop;
			state.navWidth = payload.navWidth;
    },
  },
});

export const {
  navChanged,
} = nvaSlice.actions;

export default nvaSlice.reducer;
