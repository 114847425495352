import { combineReducers, Reducer } from 'redux';
import languageReducer from './language';
import matchMediaReducer from './matchMedia';
import loginUserReducer from './loginUser';
import navReducer from './nav';
import { RootState } from '../types';

// const rootReducer: Reducer<RootState> = combineReducers<RootState>({
//   languageState: languageReducer,
// });

const rootReducer = combineReducers({
	languageState: languageReducer,
	loginUserState: loginUserReducer,
	matchMediaState: matchMediaReducer,
	navState: navReducer,
});

export default rootReducer;
