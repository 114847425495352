import { lazy } from 'react';
import type { RouteItem } from '../route';

const Home = lazy(() => import('../pages/Home'));
const Info = lazy(() => import('../pages/Info'));
const InfoDetail = lazy(() => import('../pages/Info/Detail'));
const About = lazy(() => import('../pages/About'));
const Download = lazy(() => import('../pages/Download'));
const Contract = lazy(() => import('../pages/Contract'));
const Policy = lazy(() => import('../pages/Policy'));
const Features = lazy(() => import('../pages/Features'));
const Contact = lazy(() => import('../pages/Contact'));
const Blank = lazy(() => import('../pages/Blank'));
const Login = lazy(() => import('../pages/Login'));
const Taiji = lazy(() => import('../pages/Taiji'));
const TaijiCloud = lazy(() => import('../pages/TaijiCloud'));

const routes: RouteItem[] = [
  {
    path: '/',
    element: <Home />,
  },
	{
    path: '/info',
		element: <Info />,
	},
	{
    path: '/info/:id',
		element: <InfoDetail />,
  },
  {
    path: '/about',
    element: <About />,
	},
	{
    path: '/download',
    element: <Download />,
  },
  {
    path: '/contract',
    element: <Contract />,
  },
  {
    path: '/policy',
    element: <Policy />,
  },
  {
    path: '/features',
    element: <Features />,
  },
  {
    path: '/contact',
    element: <Contact />,
	},
	{
    path: '/login',
    element: <Login />,
	},
  {
    path: '/taiji',
    element: <Taiji />
  },
  {
    path: '/tjcloud',
    element: <TaijiCloud />
  },
  {
    path: '*',
    element: <Blank />,
  },
];

export default routes;
